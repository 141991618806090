<ng-template #callingModal>
  <div mat-dialog-content class="calling-content">
    <div class="flex justify-between align-middle">
      <i class="fa-solid fa-signal f-20px text-success cursor-pointer" title="Good"></i>
      <i class="fa-solid fa-minus f-20px text-danger cursor-pointer" title="Minimize" (click)="dialog.closeAll()"></i>
    </div>
    <div class="subheading-2 text-center">
      <i class="fa-solid fa-{{callingData?.name_first_character}} f-40px caller-name"></i>
      <h2 class="f-30px mt-35px text-italic color-blue">{{callingData?.name}}</h2>
      <h2 class="f-20px text-italic color-blue">{{callingData?.contact}}</h2>
      <h2 class="f-20px text-italic mt-10px {{activity?.color_class}}" *ngIf="activity['text']">{{activity?.text}}</h2>
      <h2 class="f-20px text-italic mt-10px {{subActivity?.color_class}}" *ngIf="subActivity && subActivity['text']">{{subActivity?.text}}</h2>
      <h2 class="mt-15px text-italic text-success" *ngIf="displayTimer['seconds']">
        <span>
          <i class="fa-solid fa-circle mr-10px color-success f-20px fa-beat-fade" title="Recording under progress" *ngIf="timerId"></i>
          <i class="fa-solid fa-circle mr-10px text-danger f-20px fa-beat-fade" title="Recording stopped" *ngIf="!timerId"></i>
        </span>
        <span class="f-24px">{{displayTimer?.minutes}}:{{displayTimer?.seconds}}</span>
      </h2>
    </div>
  </div>
</ng-template>
<ng-template #receivingModal>
  <div mat-dialog-content class="calling-content">
    <div class="flex justify-between align-middle">
      <i class="fa-solid fa-signal f-20px text-success cursor-pointer" title="Good"></i>
      <i class="fa-solid fa-minus f-20px text-danger cursor-pointer" title="Minimize" (click)="dialog.closeAll()"></i>
    </div>
    <div class="subheading-2 text-center">
      <i class="fa-solid fa-{{receivingData?.name_first_character}} f-40px caller-name" *ngIf="receivingData['name_first_character']"></i>
      <h2 class="f-30px mt-35px text-italic color-blue" *ngIf="receivingData && receivingData['name']">{{receivingData?.name}}</h2>
      <i class="fa-solid fa-user-circle f-40px caller-name" *ngIf="!receivingData['name_first_character']"></i>
      <h2 class="f-30px mt-35px text-italic color-blue" *ngIf="!receivingData || !receivingData['contact']">{{receivingData?.from}}</h2>
      <h2 class="f-20px text-italic color-blue" *ngIf="receivingData && receivingData['contact']">{{receivingData?.contact}}</h2>
      <h2 class="f-20px text-italic mt-10px {{activityIncoming?.color_class}}" *ngIf="activityIncoming['text']">{{activityIncoming?.text}}</h2>
      <h2 class="f-20px text-italic mt-10px {{subActivityIncoming?.color_class}}" *ngIf="subActivityIncoming && subActivityIncoming['text']">{{subActivityIncoming?.text}}</h2>
      <h2 class="mt-15px text-italic text-success" *ngIf="displayTimerIncoming['seconds']">
        <span>
          <i class="fa-solid fa-circle mr-10px color-success f-20px fa-beat-fade" title="Recording under progress" *ngIf="timerIdIncoming"></i>
          <i class="fa-solid fa-circle mr-10px text-danger f-20px fa-beat-fade" title="Recording stopped" *ngIf="!timerIdIncoming"></i>
        </span>
        <span class="f-24px">{{displayTimerIncoming?.minutes}}:{{displayTimerIncoming?.seconds}}</span>
      </h2>

    </div>
  </div>
</ng-template>
<div
  #originRef
  (click)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative transition duration-400 ease-out-swift select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">
    {{user?.name | titlecase}}
  </div>
  <div
    class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 bg-primary-600/10">
    <img [src]="userImageUrl" class="avatar-image w-full h-full rounded-full shadow-1px-light" alt="User profile image"
         *ngIf="userImageUrl"/>
    <i class="fa-solid fa-circle-user fa-2x" *ngIf="!userImageUrl"></i>
  </div>
</div>
<div class="card calling-card" *ngIf="!hideCallingIcon">
  <i class="fa-solid fa-circle fa-beat-fade color-success caller-icon-dot"></i>
  <div class="card-body">
    <i class="fa-solid fa-headphones f-30px color-white cursor-pointer" title="Calling Info"
       (click)="openCallingModal()"></i>
  </div>
</div>
<div class="card calling-card" *ngIf="!hideIncomingCallIcon">
  <i class="fa-solid fa-circle fa-beat-fade color-success caller-icon-dot"></i>
  <div class="card-body">
    <i class="fa-solid fa-phone fa-bounce f-30px color-white cursor-pointer" title="Calling Info"
       (click)="openReceivingModal()"></i>
  </div>
</div>
