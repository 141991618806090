import {Injectable} from '@angular/core';
import {Socket, SocketIoConfig} from 'ngx-socket-io';
import {DataService} from "./data.service";
import {Device} from 'mediasoup-client'
import {environment} from "../../../environments/environment";
import {StorageService} from "./storage.service";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AlertService} from "./alert.service";

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket
  device: Device | null;
  config: SocketIoConfig = {url: environment.appUrl, options: {}};
  producerTransport: any
  roomId: string
  consumingTransports: any[] = [];
  consumerTransports: any[] = []
  params = {
    encodings: [
      {
        rid: 'r0',
        maxBitrate: 100000,
        scalabilityMode: 'S1T3',
      },
      {
        rid: 'r1',
        maxBitrate: 300000,
        scalabilityMode: 'S1T3',
      },
      {
        rid: 'r2',
        maxBitrate: 900000,
        scalabilityMode: 'S1T3',
      },
    ],
    codecOptions: {
      videoGoogleStartBitrate: 1000
    }
  }
  audioParams: any;
  videoParams: any = {params: this.params};
  localStream: any;
  audioProducer: any
  videoProducer: any
  user: any = StorageService.getItem('self')

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
  ) {
  }

  connectSocket() {
    this.socket = new Socket(this.config);
  }

  getLocalStream = async (res: any) => {

    if (res && res.type === 'userStatus') {
      this.sendToView(res)
      if (res.data === 'alreadyConnected'){
        return
      }
    }

    await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: {
          min: 320,
          max: 640,
        },
        height: {
          min: 240,
          max: 480,
        }
      }
    }).then(this.streamSuccess).catch((error: any) => {
      if (error.name === 'NotFoundError') {
        this.alertService.showErrors('Unable to start meeting. Device not found.');
        this.leaveMeeting()
      }
    })
  }

  streamSuccess = async (stream: any) => {
    this.localStream = stream
    this.audioParams = {track: stream.getAudioTracks()[0], ...this.audioParams};
    this.videoParams = {track: stream.getVideoTracks()[0], ...this.videoParams};
    this.dataService.sendMessage({
      type: 'localStream',
      data: this.localStream,
    })
    await this.joinRoom(this.roomId)
  }

  getUserStatus() {
    const msg = {
      roomId: this.roomId,
      userId: this.user.userId
    }
    this.socket.emit('getUserStatus', msg);
  }

  async joinRoom(room: any) {
    if (room) {
      const msg = {
        roomId: room,
        userDetails: this.user
      }
      this.socket.emit('joinRoom', msg);
    }
  }

  getRtpCapabilities = () => {
    this.socket.emit('getRouterRtpCapabilities', null);
  }

  async onRouterCapabilities(response: any) {
    await this.loadDevice(response.data).then(device => {
      this.createWebRtcTransport()
    }).catch((error: any) => {
      console.log(error);
    });
  }

  async loadDevice(routerRtpCapabilities: any) {
    this.device = new Device()
    try {
      await this.device.load({routerRtpCapabilities}).then(() => {
      }).catch((error: any) => {
        // console.log('Device Load Error', error)
      })
    } catch (error) {
      // console.log('loadDeviceError', error.message);
    }
  }

  connectRecvTransport = async (consumerTransport: any, remoteProducerId: any, serverConsumerTransportId: any, producerDetails: any) => {
    await this.socket.emit('consume', {
      rtpCapabilities: this.device.rtpCapabilities,
      remoteProducerId,
      serverConsumerTransportId,
      producerDetails
    }, async ({params}) => {
      // console.log('params', params)
      if (params.error) {
        // console.log('Cannot Consume')
        return
      }

      const consumer = await consumerTransport.consume({
        id: params.id,
        producerId: params.producerId,
        kind: params.kind,
        rtpParameters: params.rtpParameters
      })

      this.consumerTransports = [
        ...this.consumerTransports,
        {
          consumerTransport,
          serverConsumerTransportId: params.id,
          producerId: remoteProducerId,
          consumer,
        },
      ]

      const {track} = consumer

      const obj = {
        id: remoteProducerId,
        type: 'newStream',
        data: new MediaStream([track]),
        producerDetails
      }
      this.dataService.sendMessage(obj)

      this.socket.emit('consumerResume', {serverConsumerId: params.serverConsumerId})
    })
  }

  signalNewConsumerTransport = async (remoteProducerId: any, producerDetails: any) => {
    let userDetails = producerDetails
    let remoteProdId = remoteProducerId

    if (remoteProducerId.id) {
      userDetails = remoteProducerId.producerDetails;
      remoteProdId = remoteProducerId.id
    }

    if (this.consumingTransports.includes(remoteProdId)) {
      return;
    } else {
      this.consumingTransports.push(remoteProdId);

      await this.socket.emit('createWebRtcTransport', {consumer: true}, (params: any) => {
        if (params.error) {
          // console.log('params error')
          return
        }

        let consumerTransport: any
        try {
          consumerTransport = this.device.createRecvTransport(params.params)
        } catch (error) {
          // console.log(error)
          return
        }

        consumerTransport.on('connect', async ({dtlsParameters}, callback, errback) => {
          try {
            await this.socket.emit('transportRecvConnect', {
              dtlsParameters,
              serverConsumerTransportId: params.params.id,
            })

            callback()
          } catch (error) {
            errback(error)
          }
        })

        this.connectRecvTransport(consumerTransport, remoteProdId, params.params.id, userDetails)
      })
    }
  }

  getProducers = () => {
    this.socket.emit('getProducers', producerIds => {
      producerIds.forEach(this.signalNewConsumerTransport)
    })
  }

  connectSendTransport = async () => {

    this.audioProducer = await this.producerTransport.produce(this.audioParams);
    this.videoProducer = await this.producerTransport.produce(this.videoParams);

    this.audioProducer.on('trackended', () => {
      // console.log('audio track ended')

      // close audio track
    })

    this.audioProducer.on('transportclose', () => {
      // console.log('audio transport ended')

      // close audio track
    })

    this.videoProducer.on('trackended', () => {
      // console.log('video track ended')

      // close video track
    })

    this.videoProducer.on('transportclose', () => {
      // console.log('video transport ended')

      // close video track
    })
  }

  createWebRtcTransport() {
    this.socket.emit('createWebRtcTransport', {consumer: false}, async ({params}) => {
      if (params.error) {
        // console.log(params.error)
        return;
      }

      this.producerTransport = this.device.createSendTransport(params)

      this.producerTransport.on('connect', async ({dtlsParameters}, callback, errback) => {
        try {
          await this.socket.emit('transportConnect', {
            dtlsParameters,
          })

          callback()

        } catch (error) {
          errback(error)
        }
      })

      this.producerTransport.on('produce', async (parameters, callback, errback) => {
        // console.log('user', this.user)
        try {
          // console.log('parameters', parameters)
          await this.socket.emit('transportProduce', {
            kind: parameters.kind,
            rtpParameters: parameters.rtpParameters,
            appData: parameters.appData
          }, ({id, producersExist}) => {
            callback({id})

            if (producersExist) this.getProducers()
          })
        } catch (error) {
          errback(error)
        }
      })

      await this.connectSendTransport()
    })
  }

  async onNewProducer(response: any) {
    const producerId = response.data.producerId
    const producerDetails = response.data.producerDetails
    await this.signalNewConsumerTransport(producerId, producerDetails)
  }

  onProducerClosed(response: any) {
    let remoteProducerId = response.data.remoteProducerId
    let producerDetails = response.data.producerDetails
    const producerToClose = this.consumerTransports.find(transportData => transportData.producerId === remoteProducerId)
    producerToClose.consumerTransport.close()
    producerToClose.consumer.close()

    const obj = {
      id: producerDetails.id,
      type: 'removeMedia'
    }
    this.dataService.sendMessage(obj)

    this.consumerTransports = this.consumerTransports.filter(transportData => transportData.producerId !== remoteProducerId)
  }

  onHostClosed(response: any) {
    // console.log('hostClosed', response)
    this.dataService.sendMessage({type: 'hostClosed'})
    this.leaveMeeting()
  }

  leaveMeeting(): void {
    if (this.localStream) {
      this.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    }

    if (this.socket) this.socket.disconnect()
    this.device = null
    this.localStream = null
    this.audioProducer = null
    this.videoProducer = null
    this.audioParams = null
    this.producerTransport = null
    this.roomId = null
    this.consumingTransports = [];
    this.consumerTransports = []
  }

  sendMessage(data: any) {
    this.socket.emit('sendNewMessage', data)
  }

  sendPersonalMessage(data: any) {
    this.socket.emit('sendPersonalMessage', data)
  }

  onNewMessage(data: any) {
    this.dataService.sendMessage({
      type: 'newMessage',
      data: data
    })
  }

  getParticipants(data: any = null) {
    this.socket.emit('participants', data)
  }

  onParticipants(data: any) {
    this.dataService.sendMessage({
      type: 'participants',
      data: data
    })
  }

  async toggleScreenShare(shareScreen: boolean) {

    try {
      let stream: any

      if (shareScreen) {
        try {

          stream = await navigator.mediaDevices.getDisplayMedia({video: true})

          stream.getVideoTracks()[0].addEventListener('ended', this.screenShareEnded)
        } catch (error: any) {
          console.log(error)
          this.dataService.sendMessage({
            type: 'failedScreenShare',
          })
        }

      } else {
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {
              width: {
                min: 640,
                max: 1920,
              },
              height: {
                min: 400,
                max: 1080,
              }
            }
          })
        } catch (error: any) {
          console.log(error)
        }
      }

      if (stream) {
        this.videoProducer.pause()
        this.videoParams.track = stream.getVideoTracks()[0]
        this.videoProducer.replaceTrack({track: this.videoParams.track})
        this.videoProducer.resume()
        this.dataService.sendMessage({
          type: 'localStream',
          data: stream
        })
      } else {
        this.dataService.sendMessage({
          type: 'switchStreamFailed',
          data: null
        })
      }
    } catch (error: any) {
      console.error('Error toggling screen:', error);
    }
  }

  screenShareEnded = async()=> {
   await this.toggleScreenShare(false)
    this.sendToView({type: 'screenShareEnded'})
  }

  toggleLocalAudio(isPaused: boolean) {
    if (!isPaused) {
      this.audioProducer.pause()
      this.socket.emit('pausedSelfAudio', {producerId: this.audioProducer.id})
    } else {
      this.audioProducer.resume()
      this.socket.emit('resumedSelfAudio', {producerId: this.audioProducer.id})
    }
  }

  toggleLocalVideo(isPaused: boolean) {
    if (!isPaused) {
      this.videoProducer.pause()
      this.socket.emit('pausedSelfVideo', {producerId: this.videoProducer.id})
    } else {
      this.videoProducer.resume()
      this.socket.emit('resumedSelfVideo', {producerId: this.videoProducer.id})
    }
  }

  toggleRemoteVideo(isPaused: boolean, producerId: any) {
    if (isPaused) this.socket.emit('resumeRemoteVideo', {producerId})
    else this.socket.emit('pauseRemoteVideo', {producerId})
  }

  toggleRemoteAudio(isPaused: boolean, producerId: any) {
    if (isPaused) this.socket.emit('resumeRemoteAudio', {producerId})
    else this.socket.emit('pauseRemoteAudio', {producerId})
  }

  toggleRemoteProducerForSelf(isPaused: any, producerId: any, kind: string) {
    if (isPaused) this.socket.emit('resumeRemoteProducerForSelf', { producerId, kind })
    else this.socket.emit('pauseRemoteProducerForSelf', { producerId, kind })
  }

  sendToView(res: any) {
    this.dataService.sendMessage(res)
  }

  startRecord(roomId: any) {
    this.socket.emit('startRecord', roomId)
  }

  stopRecord(roomId: any) {
    this.socket.emit('stopRecord', roomId)
  }

  raiseHand(data: any) {
    this.socket.emit('raiseHand', data)
  }

  expel(roomId: any, socketId: any) {
    this.socket.emit('expel', {roomId, socketId})
  }

  socketResponse(): Observable<any> {
    return this.socket.fromEvent<string>('message').pipe(
      tap(async (res: any) => {
        // console.log('socketResponse', res)
        switch (res.type) {
          case 'connectionSuccess':
            this.getUserStatus();
            break;
          case 'userStatus':
            await this.getLocalStream(res);
            break;
          case 'joinedRoom':
            this.getRtpCapabilities()
            break;
          case 'routerRtpCapabilities':
            await this.onRouterCapabilities(res);
            break;
          case 'newProducer':
            await this.onNewProducer(res);
            break;
          case 'producerClosed':
            this.onProducerClosed(res)
            break;
          case 'hostClosed':
            this.onHostClosed(res)
            break;
          case 'newMessage':
            this.onNewMessage(res)
            break;
          case 'participants':
            this.onParticipants(res)
            break;
          case 'videoProducerPaused':
            this.sendToView(res)
            break;
          case 'videoProducerResumed':
            this.sendToView(res)
            break;
          case 'audioProducerPaused':
            this.sendToView(res)
            break;
          case 'audioProducerResumed':
            this.sendToView(res)
            break;
          case 'raisedHand':
            this.sendToView(res)
            break;
          case 'expelled':
            this.sendToView(res)
            break;
          case 'audioPausedByHost':
            // console.log(res)
            this.sendToView(res)
            break;
          case 'audioResumedByHost':
            // console.log(res)
            this.sendToView(res)
            break;
          case 'videoPausedByHost':
            // console.log(res)
            this.sendToView(res)
            break;
          case 'videoResumedByHost':
            // console.log(res)
            this.sendToView(res)
            break;
          case 'alreadyInMeeting':
            // console.log(res)
            this.sendToView(res)
            break;
          case 'newPersonalMessage':
            // console.log(res)
            this.sendToView(res)
            break;

        }
      })
    );
  }
}
